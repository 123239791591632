import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { ProjectPermissionsComponent } from '@ddb/containers/project-permissions/project-permissions.component';

type UserICellRendererParams = ICellRendererParams<
  UserRolesModel,
  UserRolesModel['email'],
  { componentParent: ProjectPermissionsComponent }
>;

@Component({
  selector: 'zero-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCellComponent implements ICellRendererAngularComp {
  public params?: UserICellRendererParams;

  public agInit(params: UserICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: UserICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  public handleRemoveUser(): void {
    if (this.params?.data) {
      this.params.context.componentParent.handleRemoveUserRoles(
        this.params.data,
      );
    }
  }
}
