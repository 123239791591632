import { InjectionToken, inject } from '@angular/core';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

export const DDB_HEADERS = new InjectionToken<
  RuntimeConfigurationModel['api']['ddb']['headers']
>('DDB_HEADERS', {
  providedIn: 'root',
  factory: () => inject(RUNTIME_CONFIGURATION).api.ddb.headers,
});
