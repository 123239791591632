import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { DDB_BASEURL } from '@ddb/tokens/ddb-baseurl.token';
import { DDB_HEADERS } from '@ddb/tokens/ddb-headers.token';
import { Observable } from 'rxjs';

@Injectable()
export class SetHeadersInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DDB_BASEURL)
    private readonly ddbBaseUrl: RuntimeConfigurationModel['api']['ddb']['baseUrl'],
    @Inject(DDB_HEADERS)
    private readonly ddbHeaders: RuntimeConfigurationModel['api']['ddb']['headers'],
  ) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    if (request.url.startsWith(this.ddbBaseUrl)) {
      return next.handle(
        request.clone({ headers: this.addExtraHeaders(request.headers) }),
      );
    }
    return next.handle(request);
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    for (const [key, value] of Object.entries(this.ddbHeaders as object)) {
      if (headers.has(String(key))) continue;
      headers = headers.append(String(key), String(value));
    }
    return headers;
  }
}
