import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { ProjectPermissionsComponent } from '@ddb/containers/project-permissions/project-permissions.component';

type RolesGroupIHeaderParams = IHeaderParams<
  UserRolesModel,
  { componentParent: ProjectPermissionsComponent }
>;

@Component({
  selector: 'zero-roles-header-group',
  templateUrl: './roles-header-group.component.html',
  styleUrls: ['./roles-header-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesHeaderGroupComponent implements IHeaderAngularComp {
  public params?: RolesGroupIHeaderParams;

  public agInit(params: RolesGroupIHeaderParams): void {
    this.params = params;
  }

  public refresh(params: RolesGroupIHeaderParams): boolean {
    this.params = params;
    return true;
  }
}
