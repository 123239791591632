import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { RoleName } from '@ddb/enums/role-name';
import { ProjectPermissionsComponent } from '@ddb/containers/project-permissions/project-permissions.component';

type RoleIHeaderParams = IHeaderParams<
  UserRolesModel,
  { componentParent: ProjectPermissionsComponent }
>;

@Component({
  selector: 'zero-role-header',
  templateUrl: './role-header.component.html',
  styleUrls: ['./role-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleHeaderComponent implements IHeaderAngularComp {
  public params?: RoleIHeaderParams;

  public agInit(params: RoleIHeaderParams): void {
    this.params = params;
  }

  public refresh(params: RoleIHeaderParams): boolean {
    this.params = params;
    return true;
  }

  public roleName(): RoleName | undefined {
    return this.params?.context?.componentParent?.roles?.find(
      (role) => role.id === this.params?.column?.getColDef().field,
    )?.name;
  }

  public roleNameTooltip(): string {
    switch (this.roleName()) {
      case RoleName.Reader:
        return 'can read assets for project if confidential';
      case RoleName.Editor:
        return 'can submit data to assets for project';
      case RoleName.Checker:
        return 'can save asset records';
      case RoleName.Approver:
        return 'can approve asset records';
      case RoleName.Admin:
        return 'manage user roles for project';
      default:
        return 'Unknown';
    }
  }
}
