<button
  *ngIf="params?.context?.componentParent?.userIsAdmin$ | async"
  mat-icon-button
  aria-label="Remove user"
  matTooltip="Remove user"
  (click)="handleRemoveUser()"
>
  <mat-icon>delete</mat-icon>
</button>
<mgt-person
  view="oneline"
  person-card="none"
  [personQuery]="params?.value ?? ''"
></mgt-person>
