import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SettingsStore } from '@core/state/settings.store';
import { ProjectStore } from '@ddb/state/project.store';
import { RoleStore } from '@ddb/state/role.store';
import { UsersRolesStore } from '@ddb/state/users-roles.store';
import { UsersRolesService } from '@ddb/services/users-roles.service';
import { PermissionsService } from '@core/services/permissions.service';
import { ProjectModel } from '@ddb/models/project.model';
import { RoleName } from '@ddb/enums/role-name';
import { RoleModel, NewRoleModel } from '@ddb/models/role.model';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { UserHeaderComponent } from '@ddb/components/user-header/user-header.component';
import { RolesHeaderGroupComponent } from '@ddb/components/roles-header-group/roles-header-group.component';
import { RoleHeaderComponent } from '@ddb/components/role-header/role-header.component';
import { UserCellComponent } from '@ddb/components/user-cell/user-cell.component';
import { MatDialog } from '@angular/material/dialog';
import { AssetStore } from '@asset/state/asset.store';
import {
  ColDef,
  ColGroupDef,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  Module,
  ValueGetterParams,
  ValueSetterParams,
  IRowNode,
} from '@ag-grid-community/core';
import {
  UserModalComponent,
  UserModalResult,
  USER_MODAL_CONFIG,
} from '@ddb/modals/user-modal/user-modal.component';
import {
  AcknowledgementModalComponent,
  Data as AcknowledgementModalData,
  Result as AcknowledgementModalResult,
  CONFIG as ACKNOWLEDGEMENT_MODAL_CONFIG,
  resultIsBoolean,
} from '@core/modals/acknowledgement-modal/acknowledgement-modal.component';
import { tapResponse } from '@ngrx/component-store';
import { filterSuccess } from '@core/utils/filter-success';
import {
  Observable,
  combineLatest,
  first,
  iif,
  map,
  switchMap,
  EMPTY,
} from 'rxjs';

export type GridData = UserRolesModel & {
  roles: Array<RoleModel | NewRoleModel>;
};

type GridTheme = 'ag-theme-alpine' | 'ag-theme-alpine-dark';

@Component({
  selector: 'zero-project-permissions',
  templateUrl: './project-permissions.component.html',
  styleUrls: ['./project-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPermissionsComponent {
  private readonly project$ = combineLatest([
    this.route.queryParams.pipe(map(({ project_number }) => project_number)),
    this.route.params.pipe(map(({ id }) => id)),
  ]).pipe(
    switchMap(([project_number, id]) =>
      iif(
        () => project_number !== undefined,
        this.projectStore.selectById(project_number),
        this.assetStore.selectById(id).pipe(
          filterSuccess(),
          switchMap((asset) =>
            this.projectStore.selectById(asset.data.project_number),
          ),
        ),
      ),
    ),
    filterSuccess(),
  );

  public readonly gridTheme$: Observable<GridTheme> =
    this.settingStore.theme$.pipe(
      map((theme) =>
        theme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine',
      ),
    );

  public readonly userIsAdmin$: Observable<boolean> = this.project$.pipe(
    switchMap((project) =>
      this.permissionsService.userHasRolesForResource(project.data.project_id, [
        RoleName.Admin,
      ]),
    ),
  );

  public readonly gridModules: Module[] = [ClientSideRowModelModule];
  public readonly gridOptions: GridOptions<GridData> = {
    context: { componentParent: this },
    rowModelType: 'clientSide',
    suppressRowClickSelection: true,
    singleClickEdit: true,
    defaultColDef: {
      resizable: false,
      suppressMovable: true,
    },
    getRowId: (params: GetRowIdParams<GridData>) => params.data.email,
    rowHeight: 48,
  };

  public project?: ProjectModel;
  public roles: RoleModel[] = [];

  constructor(
    private readonly settingStore: SettingsStore,
    private readonly assetStore: AssetStore,
    private readonly projectStore: ProjectStore,
    private readonly roleStore: RoleStore,
    private readonly usersRolesStore: UsersRolesStore,
    private readonly usersRolesService: UsersRolesService,
    private readonly permissionsService: PermissionsService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
  ) {}

  public onGridReady(event: GridReadyEvent<GridData>): void {
    this.project$
      .pipe(
        switchMap((project) => {
          this.project = project.data;
          return combineLatest([
            this.roleStore
              .selectById(project.data.project_id)
              .pipe(filterSuccess(), first()),
            this.usersRolesStore
              .selectById(project.data.project_id)
              .pipe(filterSuccess(), first()),
            this.userIsAdmin$,
          ]);
        }),
        first(),
      )
      .subscribe(([roles, userRoles, userIsAdmin]) => {
        this.roles = roles.data;
        event.api?.setColumnDefs([
          <ColDef<GridData, GridData['email']>>{
            field: 'email',
            headerName: 'User',
            cellDataType: 'string',
            headerComponent: UserHeaderComponent,
            cellRenderer: UserCellComponent,
            minWidth: 200,
            resizable: true,
            pinned: 'left',
            lockPosition: 'left',
          },
          <ColGroupDef<GridData>>{
            groupId: 'roles',
            headerGroupComponent: RolesHeaderGroupComponent,
            children: [
              ...Object.values(RoleName)
                .map(
                  (roleName) =>
                    roles.data.find(
                      (role) =>
                        role.name.toLowerCase() === roleName.toLowerCase(),
                    )!,
                )
                .map(
                  (role) =>
                    <ColDef<GridData, boolean>>{
                      cellDataType: 'boolean',
                      cellRenderer: 'agCheckboxCellRenderer',
                      cellEditor: 'agCheckboxCellEditor',
                      field: role.id,
                      headerComponent: RoleHeaderComponent,
                      context: { componentParent: this, role },
                      valueGetter: this.valueGetter.bind(this),
                      valueSetter: this.valueSetter.bind(this),
                      editable: userIsAdmin,
                    },
                ),
            ],
          },
        ]);
        event.api?.setRowData(userRoles.data);
        event.api?.sizeColumnsToFit();
      });
  }

  public handleAddUser(event: Event): void {
    event.preventDefault();
    this.dialog
      .open<UserModalComponent, undefined, UserModalResult>(
        UserModalComponent,
        USER_MODAL_CONFIG,
      )
      .afterClosed()
      .pipe(
        switchMap((email) => {
          const nodes: IRowNode[] = [];
          this.gridOptions.api?.forEachNode((n: IRowNode) => nodes.push(n));
          const node = nodes.find((node) => node.data?.email === email);
          const role = this.roles.find((r) => r.name === RoleName.Reader);
          return iif(
            () =>
              node === undefined && role !== undefined && email !== undefined,
            this.usersRolesService.create(email!, [role!.id]),
            EMPTY,
          );
        }),
        first(),
        tapResponse(
          (userRoles) => {
            this.gridOptions.api?.applyTransaction({ add: [userRoles] });
          },
          () => {
            // todo
            // handle error
          },
        ),
      )
      .subscribe();
  }

  public handleRemoveUserRoles(userRoles: UserRolesModel): void {
    this.dialog
      .open(AcknowledgementModalComponent, {
        ...ACKNOWLEDGEMENT_MODAL_CONFIG,
        data: <AcknowledgementModalData>{
          title: 'Remove User',
          message: `Are you sure you want to remove the user from this project?`,
          action: {
            confirm: 'Remove',
            reject: 'Cancel',
          },
          skippable: false,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result: AcknowledgementModalResult) =>
          iif(
            () => resultIsBoolean(result) && result,
            this.usersRolesService.delete(
              userRoles.user_id,
              userRoles.roles.map((role) => role.id),
            ),
            EMPTY,
          ),
        ),
        first(),
        tapResponse(
          () => {
            this.gridOptions.api?.applyTransaction({ remove: [userRoles] });
          },
          () => {
            // todo
          },
        ),
      )
      .subscribe();
  }

  private valueGetter(params: ValueGetterParams<GridData>): boolean {
    return (
      params.data?.roles.some((role) => role.id === params.colDef.field) ??
      false
    );
  }

  private valueSetter(params: ValueSetterParams<GridData>): boolean {
    const roleId: string | undefined = params.colDef.field;
    if (roleId && params.oldValue !== params.newValue) {
      if (params.oldValue === true && params.newValue === false) {
        const role = params.data?.roles.find((r) => r.id === roleId);
        if (role) {
          this.usersRolesService
            .delete(params.data?.user_id, [role.id])
            .pipe(
              first(),
              tapResponse(
                () => {
                  params.node?.setData({
                    ...params.data,
                    roles: params.data?.roles.filter(
                      (role) => role.id !== roleId,
                    ),
                  });
                },
                () => {
                  // todo
                  params.node?.setData({ ...params.data });
                },
              ),
            )
            .subscribe();
        }
      }
      if (params.oldValue === false && params.newValue === true) {
        const newRoles = [];
        const role = this.roles.find((r) => r.id === roleId);
        newRoles.push(role);
        if (role) {
          this.usersRolesService
            .create(
              params.data?.email,
              newRoles.map((role) => role!.id!),
            )
            .pipe(
              first(),
              tapResponse(
                (response) => {
                  params.node?.setData({
                    ...params.data,
                    roles: [...params.data.roles, ...response.roles],
                  });
                },
                () => {
                  // todo
                  params.node?.setData({ ...params.data });
                },
              ),
            )
            .subscribe();
        }
      }
      return params.newValue;
    }
    return params.oldValue;
  }
}
