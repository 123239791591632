import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectStore } from '@ddb/state/project.store';
import { filterSuccess } from '@core/utils/filter-success';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'zero-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSettingsComponent {
  public readonly confidential$ = this.route.queryParams.pipe(
    switchMap(({ project_number }) =>
      this.projectStore.selectById(project_number),
    ),
    filterSuccess(),
    map(({ data }) => !data.confidential),
  );

  constructor(
    private readonly projectStore: ProjectStore,
    private readonly route: ActivatedRoute,
  ) {}
}
