import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from '@ag-grid-community/angular';
import { MaterialModule } from '@material/material.module';
import { CoreModule } from '@core/core.module';
import { SetHeadersInterceptor } from '@ddb/interceptors/set-headers.interceptor';
import { ProjectSettingsComponent } from '@ddb/containers/project-settings/project-settings.component';
import { ProjectPermissionsComponent } from '@ddb/containers/project-permissions/project-permissions.component';
import { UserHeaderComponent } from '@ddb/components/user-header/user-header.component';
import { RolesHeaderGroupComponent } from '@ddb/components/roles-header-group/roles-header-group.component';
import { RoleHeaderComponent } from '@ddb/components/role-header/role-header.component';
import { UserCellComponent } from '@ddb/components/user-cell/user-cell.component';
import { UserModalComponent } from '@ddb/modals/user-modal/user-modal.component';

@NgModule({
  declarations: [
    ProjectSettingsComponent,
    ProjectPermissionsComponent,
    UserHeaderComponent,
    RolesHeaderGroupComponent,
    RoleHeaderComponent,
    UserCellComponent,
    UserModalComponent,
  ],
  exports: [ProjectSettingsComponent],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    AgGridModule,
    MaterialModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetHeadersInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DdbModule {}
