<div class="asset-access-form zero-asset-form">
  <div class="heading">
    <span>
      <h3>Project Access</h3>
      <arc-icon
        name="info-circle"
        role="img"
        aria-label="Project Access"
        tippy="Project Access"
        size="large"
      >
      </arc-icon>
    </span>
  </div>

  <div class="form">
    <zero-project-permissions class="full-width"></zero-project-permissions>
    <div class="confidential full-width">
      <mat-slide-toggle disabled [checked]="confidential$ | async">
        Confidential
      </mat-slide-toggle>
    </div>
    <zero-form-help class="full-width">
      Confidentiality is managed at a project level in Digital Design Brief
      (DDB).
    </zero-form-help>
  </div>
</div>
