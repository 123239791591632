<div mat-dialog-content>
  <mat-form-field>
    <zero-people-picker
      selectionMode="single"
      placeholder="Search for a user"
      [formControl]="userEmailControl"
    ></zero-people-picker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancle</button>
  <button mat-button [mat-dialog-close]="userEmailControl.value">Save</button>
</div>
