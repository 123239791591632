import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEntityStore } from '@core/state/http-entity.store';
import { RoleService } from '@ddb/services/role.service';
import { RoleModel } from '@ddb/models/role.model';
import { tapResponse } from '@ngrx/component-store';
import { switchMap, expand, reduce, EMPTY } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoleStore extends HttpEntityStore<RoleModel[]> {
  constructor(
    private readonly roles: RoleService,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  loadById = this.effect<RoleModel['id']>((resourceId$) =>
    resourceId$.pipe(
      switchMap((resourceId) => {
        this.setLoading(resourceId);
        return this.roles.getRoles(resourceId).pipe(
          expand((response) =>
            response.paging.next && response.paging.cursors?.after
              ? this.roles.getRoles(resourceId, response.paging.cursors.after)
              : EMPTY,
          ),
          reduce(
            (acc, response) => acc.concat(response.roles),
            <RoleModel[]>[],
          ),
          tapResponse(
            (roles) => this.setSuccess([resourceId, roles]),
            (error: HttpErrorResponse) => {
              this.setError([resourceId, error]);
              // TDOO
              this.snackBar.open(
                `Failed to load roles for ${resourceId}`,
                'Dismiss',
              );
            },
          ),
        );
      }),
    ),
  );
}
