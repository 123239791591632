import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';

export const USER_MODAL_ID = 'user-modal';

export type UserModalResult = string | null;

export const USER_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
  id: USER_MODAL_ID,
  width: '100%',
  maxWidth: '600px',
};

@Component({
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserModalComponent {
  public readonly userEmailControl = new FormControl<UserModalResult>(null);
}
