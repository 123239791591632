import { Injectable, Inject } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { RoleModel } from '@ddb/models/role.model';
import { HttpPaginatedResponse } from '@ddb/types/http-pagination-response';
import { ROLE_ENDPOINT } from '@ddb/tokens/role-endpoint.token';
import { Observable as Obs } from 'rxjs';

type Resposne = HttpPaginatedResponse & { roles: RoleModel[] };

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(
    @Inject(ROLE_ENDPOINT) private readonly endpoint: string,
    private readonly http: HttpService,
  ) {}

  getRoles(resource_id: string, after: string | null = null): Obs<Resposne> {
    const params = { resource_id, ...(after && { after }) };
    return this.http.client
      .get<Resposne>(this.endpoint, { params, observe: 'response' })
      .pipe(this.http.trackError(), this.http.body());
  }
}
