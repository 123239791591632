import { Inject, Injectable } from '@angular/core';
import {
  LocalStorageKey,
  LocalStorageDataKey,
} from '@core/types/local-storage-key';
import { LOCAL_STORAGE } from '@core/tokens/local-storage.token';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private localStorage: Storage) {}

  getItem<T>(key: LocalStorageKey | LocalStorageDataKey) {
    const data = localStorage.getItem(key);
    return (data ? JSON.parse(data) : data) as T;
  }

  setItem(key: LocalStorageKey | LocalStorageDataKey, data: unknown) {
    localStorage.setItem(key, JSON.stringify(data));
  }
}
